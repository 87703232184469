import React, { useState } from 'react';
import { Col, Divider, Row, Popover, Button, Collapse, Image } from 'antd';
import Layout from '../../components/Layouts';
import { Scrollama, Step } from 'react-scrollama'
import injectSheet from 'react-jss';
import {createUseStyles} from 'react-jss'
import { Step0, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, Step10, Step11, Step12, Step13 } from './story';
import { Content0, Content1, Content2,Content3, Content4, Content5, Content6, Content7, Content8, Content9, Content10, Content11, Content12, Content13 } from './story';

const { Panel } = Collapse;


export const frontmatter = {
    title: `Word Wiser: Wiser by Words`,
    written: `2021-12-21`,
    updated: `2021-01-01`,
    layoutType: `post`,
    contentType: `dataviz`,
    path: `/wordwiser/`,
    category: `VISUALISATION, TEXT ANALYTICS`,
    image: `./poster.png`,
    description: `Are you making effective use of your customer feedback?`
}

const useStyles = createUseStyles({
    layoutContainer: {
        maxWidth: "960px",
        margin: "0px auto",
        fontFamily: "Work Sans, sans-serif",
        lineHeight: 1.4,
        fontSize: "1.4em",
      },
      '@media screen and (min-width: 480px) and (max-width: 800px)': {
        layoutContainer: {
            maxWidth: "960px",
            margin: "0px auto",
            fontFamily: "Work Sans, sans-serif",
            lineHeight: 1,
            fontSize: ".8em",
          }    
      },
      greenDude: {
        backgroundColor: "#a0db8e",
        color: "green",
      },      
      blueDude: {
        backgroundColor: "#00b4d8",
        color: "darkblue",
      },

    navbar: {
        position: 'fixed',
        display: 'flex',
        top: 0,
        right: 0,
        zIndex: 1,
        '& a': {
            display: 'block',
            fontSize: '20px',
            padding: '20px',
        },
    },
    pageTitle: {
        textAlign: 'center',
        fontSize: 22,
        margin: '90px 0 10px',
        visibility: 'hidden',
    },
    description: {
        maxWidth: 600,
        margin: '10px auto 30px',
        fontSize: 22,
        lineHeight: '28px',
        '& a': {
            color: 'black',
        },
    },
    pageSubtitle: {
        textAlign: 'center',
        fontSize: 22,
        color: '#888',
    },
    graphicContainer: {
        padding: '10vh 2vw 20vh',
        display: 'flex',
        justifyContent: 'space-between',
    },
    graphic: {
        flexBasis: '60%',
        position: 'sticky',
        width: '100%',
        height: '60vh',
        top: '10vh',
        // backgroundColor: '#aaa',
        backgroundColor: 'whitesmoke',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: '1.2rem',
            fontWeight: 500,
            textAlign: 'center',
            color: 'gray',
        },
    },
    scroller: {
        flexBasis: '35%',
    },
    step: {
        margin: '0 auto 2rem auto',
        // padding: '180px 0',
        // border: '1px solid #333',
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        '& p': {
            textAlign: 'center',
            padding: '.6rem',
            fontSize: '1.3rem',
            lineHeight: '2.4rem',
            margin: 0,
            backgroundColor: "#3b3b3b"
        },
        '&:last-child': {
            marginBottom: 0,
        },
    },
    '@media screen and (max-width: 480px)': {
        step: {
            margin: '0 auto 2rem auto',
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            '& p': {
                textAlign: 'center',
                padding: '.6rem',
                fontSize: '1rem',
                lineHeight: '1.2rem',
                margin: 0,
                backgroundColor: "#3b3b3b"
            },
            '&:last-child': {
                marginBottom: 0,
            },            
        }
    },
    videoContainer: {
        position: "relative",
        paddingBottom: "56.25%",
        height: 0,
        width: '100%',
        '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
        }
      },
      
    button: {
        backgroundColor: '#3773ac',
        color: 'white',
        borderRadius: '4px',
        cursor: 'pointer',
        padding: '6px',
        textAlign: 'center',
        display: 'block',
        maxWidth: 220,
        margin: '10px auto 30px',
        fontSize: 19,
        lineHeight: '28px',
        textDecoration: 'none',
    },
    subhed: {
        maxWidth: 600,
        margin: '10px auto 15px',
        fontSize: 22,
        lineHeight: '28px',
        '& a': {
            color: 'black',
        },
        textAlign: 'center',
    },
    stepContent: {
        backgroundColor: "whitesmoke",
        color: "black"
      },    
    linker: {
        color: "gray", 
        borderBottom: "2px solid #E2DDCA",
        '&:hover': {
            backgroundColor: '#E2DDCA',
        }
      },
    whoUsing: {
        maxWidth: 960,
        margin: '30px auto 100px',
        fontSize: 19,
        lineHeight: '26px',
        gridAutoRows: 'minmax(100px, auto)',
        '& a': {
            color: 'black',
        },
        '& img': {
            width: '100%',
        },
        display: 'grid',
        gridTemplateColumns: '2fr 5fr',
        '& > div': {
            padding: '16px 0',
            borderTop: '1px solid #ccc',
            '&:nth-child(odd)': {
                paddingRight: '13px',
                borderRight: '1px solid #ccc',
            },
            '&:nth-child(even)': {
                paddingLeft: '13px',
            },
        },
    },
});

const OverlayStep = ({ classes, step, i, data }) => {
    switch (step) {
        case 0: {
            return <Step0 classes={classes} />
        }
        case 1: {
            return <Step1  classes={classes} />
        }
        case 2: {
            return <Step2  classes={classes} />
        }
        case 3: {
            return <Step3  classes={classes} />
        }
        case 4: {
            return <Step4  classes={classes} />
        }
        case 5: {
            return <Step5  classes={classes} />
        }
        case 6: {
            return <Step6  classes={classes} />
        }
        case 7: {
            return <Step7  classes={classes} />
        }
        case 8: {
            return <Step8  classes={classes} />
        }
        case 9: {
            return <Step9  classes={classes} />
        }
        case 10: {
            return <Step10  classes={classes} />
        }
        case 11: {
            return <Step11 classes={classes} />
        }
        case 12: {
            return <Step12  classes={classes} />
        }
        case 13: {
            return <Step13  classes={classes} />
        }
        default: {
            return <div>Not Implemented</div>
        }
    }
}

const ContentStep = ({ classes, step, i, data }) => {

    switch (step) {
        case 0: {
            return <Content0  classes={classes} />
        }
        case 1: {
            return <Content1  classes={classes} />
        }
        case 2: {
            return <Content2  classes={classes} />
        }
        case 3: {
            return <Content3  classes={classes} />
        }
        case 4: {
            return <Content4  classes={classes} />
        }
        case 5: {
            return <Content5  classes={classes} />
        }
        case 6: {
            return <Content6  classes={classes} />
        }
        case 7: {
            return <Content7  classes={classes} />
        }
        case 8: {
            return <Content8  classes={classes} />
        }
        case 9: {
            return <Content9  classes={classes} />
        }
        case 10: {
            return <Content10  classes={classes} />
        }
        case 11: {
            return <Content11  classes={classes} />
        }
        case 12: {
            return <Content12  classes={classes} />
        }
        case 13: {
            return <Content13  classes={classes} />
        }
        // case 14: {
        //     return <Content14 />
        // }
   
        default: {
            return (
                <div className="step" key={i}>
                    <h1>{step}</h1>
                    <div style={{ height: 300, backgroundColor: 'orange' }}></div>
                </div>
            )

        }
    }
}

const SensitiveContent = (
    <div style={{backgroundColor:"whitesmoke"}}>
        <iframe src="https://giphy.com/embed/o69VOPKrAy893QGIKE" width="320" height="287" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/o69VOPKrAy893QGIKE"></a></p>
    </div>
  );

function WordWiser(props) {
    const { data, location } = props
    const classes = useStyles()
    const [currentStepIndex, setCurrentStepIndex] = useState(null);
    const [tokenContext, setTokenContext] = useState('🍅')

    // This callback fires when a Step hits the offset threshold. It receives the
    // data prop of the step, which in this demo stores the index of the step.
    const onStepEnter = ({ data }) => {
        setCurrentStepIndex(data);
    };

    const showBanana = () => {
        setTokenContext('🍌')
    }

    const showTomato = () => {
        setTokenContext('🍅')
    }

    return (
        <div className="dataviz-page" style={{ backgroundColor: "whitesmoke"}}>
            <Layout data={data} location={location}>
                {/* <div style={{ maxWidth: "960px", margin: "0px auto", fontFamily: "Work Sans, sans-serif", lineHeight: 1.4, fontSize: '1.4em', WebkitFontSmoothing: "antialiased" }}> */}
                <div className={classes.layoutContainer}>
                    <h1 className="header-title">⌠⌠ What Are Your Customers Telling You? ⌡⌡</h1>
                    <p
                        className="header-subtitle"
                        style={{ marginTop: 20, marginBottom: 10 }}
                    >          
                        Desktop Version | 10 Jan, 2022
                    </p>

                    <div style={{paddingTop: 40}}>
                        <p style={{paddingTop: 20}}>
                            <strong>Metaverse Inc</strong> has launched a new online marketplace: <a href='https://bit.ly/3ynd6NI'>sustainable-quantum-chain.ai</a>. 
                        </p>
                        <p style={{paddingTop: 40}}>                                                        
                            The platform is designed to help users <span style={{backgroundColor: "khaki", paddingTop: 2}}>buy and sell freshly minted sustainable fashion products</span>. The buyers can own the 
                            product and get a <span style={{backgroundColor: "khaki", paddingTop: 2}}>JPEG copy</span> and the blockchain-backed proof of ownership. And before you ask, yes, they use 
                            Casper (the energy-efficient algorithm 😉)                                
                        </p>
                        <p style={{paddingTop: 40}}>
                            And it is fully decentralized.
                        </p>
                        <p style={{paddingTop: 40}}>
                            Non <s>Fungal</s> Fungible <a href="https://solsea.io/collection/61336ef2eb3d9e318ee8a2df" target='_blank' onMouseOver={showBanana} onMouseOut={showTomato}>{tokenContext}</a> <a href="#rf3"><sup>3,</sup></a><a href="#rf4"><sup>4,</sup></a><a href="#rf7"><sup>7</sup></a>
                        </p>
                        {/* <p style={{paddingTop: 40}}>
                            Pixelated 🦧 says <b>Big If True</b><a href="#rf4"><sup>4</sup></a> [<a href='' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Warning: Viewer Discretion Advised</a>]                            
                        </p> */}
                        <div style={{ paddingTop: 40}}>
                            <span>Pixelated 🦧 tweets <b>Big If True</b><a href="#rf5"><sup>5</sup></a>&nbsp;</span>
                        <Popover content={SensitiveContent} >
                            .Yet some say [<Button type="link" size='large'>☢️ Warning: Viewer Discretion Advised</Button>]
                        </Popover>                            
                        </div>
                        <p style={{paddingTop: 40}}>
                            It is a tremendous step forward for the fashion industry. 
                            The platform has gone viral among fashion enthusiasts and has become a popular platform for the community to buy and sell sustainable products.
                        </p>
                        <p style={{paddingTop: 40}}>
                            The businesses take note of the platform and start to use it. 
                            Our story follows the journey of two such owners, <span className={classes.greenDude}>Green</span> and <span className={classes.blueDude}>Blue</span>, who are both passionate about fashion, sustainability, and sustainable fashion.
                        </p>
                    </div>

                    {/* <div style={{ margin: '5vh 0', border: '2px dashed skyblue' }}> */}
                    <div style={{ margin: '5vh 0'}}>
                    {/* <div style={{ position: 'sticky', top: 0, border: '1px solid orchid' }}> */}
                    {/* <div style={{ position: 'sticky', top: 0,  }}>
                            I'm sticky. The current triggered step index is: {currentStepIndex}
                        </div> */}
                        <div className={classes.graphicContainer}>
                            <div className={classes.scroller}>
                                <Scrollama
                                    offset={0.5}
                                    // threshold={1.5}
                                    onStepEnter={onStepEnter} 
                                    // debug
                                >
                                    {[1, 2, 3, 4, 5, 6,7, 8, 9, 10, 11, 12, 13, 14].map((_, stepIndex) => (
                                        <Step data={stepIndex} key={stepIndex}>
                                            <div
                                                // className={classes.step}
                                                // className="center"
                                                style={{
                                                    height: "200px",
                                                    marginTop: '60vh',
                                                    border: '1px solid whitesmoke',
                                                    opacity: currentStepIndex === stepIndex ? 1 : 0.3,
                                                    backgroundColor: currentStepIndex === stepIndex ? '#3b3b3b' : 'whitesmoke',
                                                }}
                                            >
                                                {/* I'm a Scrollama Step of index {stepIndex} */}
                                            <OverlayStep step={stepIndex} i={stepIndex}  classes={classes} />
                                            </div>
                                        </Step>
                                    ))}
                                </Scrollama>
                            </div>
                            <div className={classes.graphic}>
                                <ContentStep step={currentStepIndex} i={0} data={0}  classes={classes} />
                            </div>
                        </div>
                    </div>
                    <div style={{textAlign: "center", lineHeight: 2, color: "gray", fontFamily: "fantasy", fontSize: "2rem"}}>
                        <p>
                            Thanks for scrolling. I hope you enjoyed it.
                        </p>
                        <p>
                        ❆ ❆ ❆ ❆ ❆ ❆ ❆  end.eth<a href="#rf1"><sup>1,</sup></a> ❆ ❆ ❆ ❆ ❆ ❆ ❆ ❆ 
                        </p>
                        <p style={{color: "gray", fontSize: ".9rem", paddingTop: 20}}>
                            Disclaimer: This is a personal blog. Any views or opinions represented in this blog are personal and belong solely to the blog owner and do not represent those of people, 
                            institutions or organizations that the owner may or may not be associated with in professional or personal capacity, unless explicitly stated.
                        </p>
                    </div>
                    <div style={{marginBottom: 50, paddingTop: "70vh", willChange: "background", backgroundColor: "#f5f5f5"}}>
                        <p style={{fontSize: "2rem", lineHeight: "3rem", fontWeight: 500, marginBottom: "3rem"}}>
                            Well .... 😬
                        </p>
                        <p style={{fontSize: "2rem", lineHeight: "3rem", fontWeight: 500, marginBottom: "3rem"}}>
                            What if you could really try out Word Wiser ™️?
                        </p>
                        <img src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/wordwise-tm-3.1-low.jpg" alt="..." loading="lazy"/>                        
                        <div style={{marginTop: 20, marginBottom: 20}}>
                        <p style={{paddingTop: 30, paddingBottom: 30}}>
                            Like <span className={classes.blueDude}>Blue</span> and <span className={classes.greenDude}>Green</span>, most sellers would not want to spend their time and money building and managing such solutions.
                        </p>
                        <Collapse defaultActiveKey={['']} style={{ fontFamily: "Work Sans, sans-serif", lineHeight: 1.4, fontSize: '1em', WebkitFontSmoothing: "antialiased" }}>                            
                            <Panel header="Managed End-to-End Machine Learning Solution" key="C1">
                                <p style={{paddingBottom: 20 }}>
                                    On the other hand, a managed solution is a solution that is built and managed by a team of experts. 
                                    It abstracts away the complexity of the machine learning process and allows the team to focus on the core business of the solution<a href="#rf7"><sup>8</sup></a>.
                                    The managed solution takes care of the following:
                                </p>
                                <ul>
                                    <li>✧ Data Labelling and Versioning</li>
                                    <li>✧ Customised Model Development and Training</li>
                                    <li>✧ Model Versioning, Deployment, Monitoring, and Retraining</li>
                                    <li>✧ Cloud Infrastructure, Hardware Accelerator, and more</li>
                                </ul>
                            </Panel>
                        </Collapse>
                        </div>                                                
                        <div style={{marginTop: 20}}>
                        <p style={{paddingTop: 30, paddingBottom: 30}}>
                            At this point, you may well be thinking, "The story, the cartoon, 
                            and even the Word Wiser™️ screenshot are alright, but when can I try it? 
                            Does it even exist, or Is it just another PowerPoint-Esque blue sky purple yolk ramble?"
                            
                        </p>
                            <Collapse defaultActiveKey={['']} style={{ fontFamily: "Work Sans, sans-serif", lineHeight: 1.4, fontSize: '1em', WebkitFontSmoothing: "antialiased" }}>
                                <Panel header="WORD WISER: PREVIEW VIDEO 🎬" key="1">
                                <p style={{paddingTop: 30, paddingBottom: 30}}>
                                    Alpha version of Word Wiser™️ in action with code names<a href="#rf9"><sup>9</sup></a> for each sub module. 
                                </p>
                                <div className={classes.videoContainer}>
                                    <iframe width="800" height="800" src="https://www.youtube.com/embed/piuCsOdDWfQ" title="Word Wiser: Hello World" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                            
                                </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                    <Divider />
                {/* <footer style={{ minWidth: 960, paddingLeft: 10, paddingBottom:20, textAlign: "center", fontFamily: "ColfaxAI,Helvetica,sans-serif", fontSize: "1rem", fontWeight: "unset", color: "gray", lineHeight: 1.6}}> */}
                    <Row style={{ paddingLeft: 10, paddingBottom:20, textAlign: "center", fontFamily: "ColfaxAI,Helvetica,sans-serif", fontSize: "1rem", fontWeight: "unset", color: "gray", lineHeight: 1.6}}>
                        <Col span={4}>Notes</Col>
                        <Col span={20} style={{textAlign: "left"}}>
                            <Row>
                                <Col span={24}>1. All characters in this satire are fictitious. Any resemblance to real life is purely coincidental.</Col>
                            </Row>
                            <Row>
                                <Col span={24}>2. No apes were bored during the making of this story except for one.</Col>
                            </Row>
                            <Row id="rf3">
                                <Col span={24}>
                                    3. At least, <a className={classes.linker} href='https://www.nytimes.com/2019/12/07/arts/art-basel-banana-eaten.html' target='_blank'>the pixelated JPEG cannot be eaten</a>
                                </Col>                            
                            </Row>
                            <Row id="rf4">
                                <Col span={24}>4. Jack's Tweet < a className={classes.linker} href="https://twitter.com/jack/status/1473139010197508098?s=20" target='_blank'>You don’t own web3</a></Col>
                            </Row>
                            <Row id="rf5">
                                <Col span={24}>5. Rap: Everybody's retweeting now. Power grids run out, <a className={classes.linker} href="https://open.spotify.com/track/77Ft1RJngppZlq59B6uP0z" target='_blank'>times up, over, blaow</a>. Snap back to reality, Ape there goes sanity. Ape there goes.</Col>
                            </Row>
                            <Row id="rf6">
                                <Col span={24}>6. Word Wiser is just a code name and has no relation with (™️) as of now</Col>
                            </Row>
                            <Row id="rf7">
                                <Col span={24}>7. Website: <a className={classes.linker} href="https://web3isgoinggreat.com/" target='_blank'>Web 3 is going just great</a></Col>
                            </Row>
                            <Row id="rf8">
                                <Col span={24}>8. This section was written with the help of GPT-3</Col>
                            </Row>
                            <Row id="rf9">
                                <Col span={24}>9. 😬 No, Kevin 6G did not help with the code names</Col>
                            </Row>
                        </Col>
                    </Row>
                </div>                                
            </Layout>            
        </div>
    )
}

// export default injectSheet(styles)(WordWiser);
export default WordWiser;
