import { highlight } from 'prismjs'
import React from 'react'
// import './wiser.css'
// import ProgressiveImage from 'react-progressive-image'



export const StepTemplate = ({title, body, footer}) => {
    return (
        <div className="step">
            <p>{title}</p>
            <p>{body}</p>
            <p>{footer}</p>
        </div>
    )    
}


export const Step0 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>Meet <span className={classes.greenDude}>Green</span>. He is the owner of <b>E-Lon & Cleon Co.</b></p>
            <p><span className={classes.blueDude}>Blue</span> is the owner of <b>Day Dawn Musk Store</b></p>
        </div>
    )
}

export const Content0 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            {/* <ProgressiveImage src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/char-intro.jpg" placeholder="https://s3.amazonaws.com/katnoria.com/static/wordwiser/char-intro-sm.jpg">
            {src => <img src={src} alt="an image" />}
            </ProgressiveImage>                         */}
            <p>
                <img 
                    style={{backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/char-intro.jpg" }}
                    src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/char-intro.jpg" 
                />
            </p>
            <p>Blue: "Hi there"</p>
            <p>Green: "Hello, Nice to meet you too"</p>
            
        </div>
    )
}

export const Step1 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>Both are excited to try out the <b>Metaverse Marketplace</b>.</p>
            <p>                
                The platform fee bothers them a bit, but they are willing to pay.                
            </p>
        </div>
    )
}

export const Content1 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <p>
                <img 
                    style={{
                        backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/sales-up.jpg)",
                        width: 529,
                        height: 238
                     }}
                    src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/sales-up.jpg"                     
                    loading="lazy"

                />
            </p>            
            <p >It seems to work out quite well so far</p>
        </div>
    )
}

export const Step2 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>❖ Value Added Insights ❖ </p>
            <p>
                The platform provides value-added insights. 
                The interface is excellent and perhaps one of the best ever known to mankind.
            </p>
            <p>
                The font kerning, the colors, the rounded borders, the screen transitions are very well thought out. 
                Very pleasing to the eye.
            </p>
            <p>
                Alas, both <span className={classes.greenDude}>Green</span> and <span className={classes.blueDude}>Blue</span> seldom use it.
            </p>
        </div>
    )
}

export const Content2 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img 
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/platform-reportsv2.jpg)",
                    width: 458,
                    height: 801                    
                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/platform-reportsv2.png"
                loading="lazy"                
            />            
            <p >
                They can only get what they need by spending considerable manual effort. And perhaps that is why they see no incentive to use the reports frequently.                
            </p>
        </div>
    )
}

export const Step3 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>A few more months pass.</p>
            <p>                
                Competition is heating up. A lot more new sellers have begun to come online.
            </p>
            <p>
                Meanwhile, for Blue and Green, the sales seem to be decreasing.
            </p>            
        </div>
    )
}

export const Content3 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img 
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/competition.jpg)",
                    width: 499,
                    height: 419                    

                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/competition.png"
                loading="lazy"                 
            />
            <p >
                    The increased competition requires both of them to up their game.
            </p>
        </div>
    )
}

export const Step4 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>
                <span className={classes.greenDude}>Green</span> decides to increase brand awareness through online means such as sharing content on social media websites and purchasing ads wherever he can.                                 
            </p>
            <p>                
                The sales seem to improve, and <span className={classes.greenDude}>Green</span> feels good about his steps. He assumes it is the direct result of his branding efforts.        
            </p>
        </div>
    )
}

export const Content4 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img 
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/searcher-2.jpg)",
                    width: 500,
                    height: 606                    

                }}
            src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/searcher-2.jpg" alt="..." loading="lazy"/>
        </div>
    )
}

export const Step5 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>
                Meanwhile, <span className={classes.blueDude}>Blue</span> observes that <b>Day Dawn Musk Store</b> has started showing up 
                more often on digital platforms.</p>
            <p> He takes note and does the same. </p>
            <p>                
                Things start to improve for <span className={classes.greenDude}>Green</span> as well.                 
            </p>
        </div>
    )
}

export const Content5 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/instafluencer-2.jpg)",
                    width: 500,
                    height: 577                    

                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/instafluencer-2.jpg" alt="..." loading="lazy"/>            
            <p>
                💭 Both think that they must be doing something right.
            </p>
        </div>
    )
}

export const Step6 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>
                Nonetheless, after a few months, the sales start to drop again.
            </p>
            <p>
                Increased flow has made it challenging to keep up with the orders. The store ratings have changed.
            </p>
            <p>
                ★ ★ ✩ ✩ ✩
            </p>
        </div>
    )
}

export const Content6 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img 
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/reviews.jpg)",
                    width: 500,
                    height: 405                    

                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/reviews2.png" alt="..." loading="lazy"/>
            <p>
                People are leaving reviews.
            </p>
            <p >
                They both scan through some of the reviews, but it is hard to read several hundred reviews.
            </p>
        </div>
    )
}

export const Step7 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>                
                <span className={classes.greenDude}>Green</span> responds by offering discount coupons.
            </p>
            <p>
                <span className={classes.blueDude}>Blue</span> decides to identify the root cause of the problem.                
            </p>
            <p>
                He feels his customers are trying to tell him something and, he should probably look into it.
            </p>
        </div>
    )
}

export const Content7 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/next-best-soln2.jpg)",
                    width: 499,
                    height: 327                    

                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/next-best-soln2.jpg" alt="..." loading="lazy"/>
            <p >
                He discovers this text analytics tool on the 42nd page of search results. 🔌 Alert: <a href="https://katnoria.com/wordwiser">katnoria.com/wordwiser</a>.
            </p>
        </div>
    )
}


export const Step8 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>                
                Enter Word Wiser™️ <a href="#rf6"><sup>6</sup></a>
            </p>
            <p>
                <span className={classes.blueDude}>Blue</span> can quickly gather a quick but coarse overview of the feedback.
            </p>
        </div>
    )
}

export const Content8 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/wordwiser-1_1.jpg)",
                    width: 500,
                    height: 411                    
                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/wordwiser-1_1.jpg"alt="..." loading="lazy" />            
        </div>
    )
}

export const Step9 = ({classes}) => {
    return (
<div className={classes.step}>
            <p>                
                Word Wiser enables <span className={classes.blueDude}>Blue</span> to selectively focus on the feedback he is interested in.
            </p>
            <p>
                He can selectively glance through the reviews with negative sentiment. 
                Perhaps generate a word cloud from all the negative reviews and see what words show up most often.
            </p>
        </div>
    )
}

export const Content9 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/wordwiser-filter-sentiments.jpg)",
                    width: 500,
                    height: 694                    
                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/wordwiser-filter-sentiments.png" alt="..." loading="lazy"/>
        </div>
    )
}


export const Step10 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>                
                But that is not all.
            </p>
            <p>             
                It is easier for <span className={classes.blueDude}>Blue</span> to see what products are popular.
            </p>
            <p>
                Which ones get mentioned the most in negative reviews.               
            </p>
            <p>
                Is there an issue with a specific  kind of product or group of products? Is delivery an issue?                 
            </p>
        </div>        
    )
}

export const Content10 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/wordwise-3_21.jpg)",
                    width: 500,
                    height: 598                    
                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/wordwise-3_21.jpg" alt="..." loading="lazy"/>
            <p >
                Word Wiser can also extract entities from the reviews.                
            </p>
        </div>
    )
}

export const Step11 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>                
                Interactively explore customer reviews.
            </p>
            <p>
                Word Wiser enables <span className={classes.blueDude}>Blue</span> to slice and dice reviews based on sentiment, entity, and specific keywords.                
            </p>
        </div>
    )
}

export const Content11 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/wordwiser-5.1.jpg)",
                    width: 500,
                    height: 348                    
                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/wordwiser-5.1.png" alt="..." loading="lazy"/>
            <p >
            He can <b>identify the issues</b> and <span style={{backgroundColor: "hsl(51,98%,81%)"}}>focus his time and money on fixing</span> them.
            </p>
        </div>
    )
}

export const Step12 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p>                
                Things start to improve for <span className={classes.blueDude}>Blue</span>.                
            </p>
            <p>
                ★ ★ ★ ★ ✩
            </p>
            <p>                
                Meanwhile, <span className={classes.greenDude}>Green</span> continues to offer a discount, buy more ad space which helps less than most often. He has failed to address the real problem 😔.                
            </p>
        </div>
    )
}

export const Content12 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <img
                style={{
                    backgroundImage: "url(https://s3.amazonaws.com/katnoria.com/static/wordwiser/sm/things-changed.jpg)",
                    width: 499,
                    height: 323                    
                }}
                src="https://s3.amazonaws.com/katnoria.com/static/wordwiser/things-changed.png" alt="..." loading="lazy"/>            
        </div>
    )
}

export const Step13 = ({classes}) => {
    return (
        <div className={classes.step}>
            <p >
                It is not because <span className={classes.greenDude}>Green</span> does not want to fix the root cause.
            </p>            
        </div>
    )
}

export const Content13 = ({classes}) => {
    return (
        <div className={classes.stepContent}>
            <p style={{ paddingTop: "40vh"}}>
                It's because he does not know what the problem is.
            </p>            
            <p style={{fontSize: "1.8rem", lineHeight: "2.4rem", fontWeight: 400}}>
                He does not have Word Wiser™️ 😎
            </p>            
        </div>
    )
}
